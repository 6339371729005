<template>
  <div class="studymain" @click="showTip = false">
    <audio ref="audio" autoplay controls :src="currentWord[sound + 'fy']"
           style="width: 0;height: 0;position: absolute;top: 0;">您的浏览器不支持 audio 标签。
    </audio>
    <studyHeader @back="back"/>
    <div class="studyBox" id="studyIpad">
      <div class="main" v-if="content !== 'test'">
        <!-- 收藏功能 -->
        <div class="squirrel" v-if="source === 'studyway' && content == 'default'">
          <img :src='"../../../assets/images/student/" + (currentWord.sfsc ? "no" : "") + "squirrel.png"' alt=""
               @click="collect(currentWord.sfsc)">
        </div>
        <!-- 头部 -->
        <div class="unitsTitle">
          <img src="../../../assets/images/student/unitsTitle.png" alt="">
          <div class="text">学习内容</div>
        </div>
        <!-- 初始 -->
        <div class="default" v-if="content == 'default'">
          <div class="progress">
            <el-progress :text-inside="true" :stroke-width="16"
                         :percentage="(currentIndex + 1) / wordList.length * 100"></el-progress>
            <div class="text">{{ currentIndex + 1 + '/' + wordList.length }}</div>
          </div>
          <div class="word">{{ currentWord.nr }}</div>
          <div class="audio">
            <img src="../../../assets/images/student/horn.png" alt="" @click="play">
          </div>
          <div class="mean">{{ currentWord[sound + 'yb'] }}</div>
          <div class="btns">
            <div class="btnleft left" @click="isShuxi(1)">
              <img src="../../../assets/images/student/cooked.png" alt="">
              <span class="btntext">熟悉</span>
            </div>
            <div class="btnright right" @click="isShuxi(0)">
              <img src="../../../assets/images/student/Unfamiliar.png" alt="">
              <span class="btntext">不熟悉</span>
            </div>
          </div>
        </div>
        <!-- 第一轮测试 -->
        <div class="test1" v-if="content == 'test1'">
          <div class="header">
            <div class="title">请选择正确的词义</div>
            <p class="time"> 剩余 <span>{{ time }}</span> 秒 </p>
          </div>
          <div class="wordaudio">
            <div class="word">{{ currentWord.nr }}
              <div class="img" @click="play">
                <img src="../../../assets/images/student/horn.png" alt="">
              </div>
            </div>
          </div>
          <div class="options">
            <div class="option" v-for="item in optionsChinese" :key="item">
              <div class="answer" @click="doTest1(item)"
                   :style="ansYes === item ? 'background: #8BDF1F;color: #fff;' : (ansNo === item ? 'background: #FF4545;color: #fff;' : '')">
                {{ item }}
                <!-- 正确 -->
                <div class="icon" v-if="ansYes === item">
                  <img src="../../../assets/images/student/right.png" alt="">
                </div>
                <!-- 错误 -->
                <div class="icon" v-if="ansNo === item">
                  <img src="../../../assets/images/student/Wrong.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 学习 -->
        <div class="study" v-if="content == 'study'">
          <div class="progress">
            <el-progress :text-inside="true" :stroke-width="16"
                         :percentage="(currentIndex + 1) / wordList.length * 100"></el-progress>
            <div class="text">{{ currentIndex + 1 + '/' + wordList.length }}</div>
          </div>
          <div class="wordaudio">
            <div class="word">{{ currentWord.nr }}
              <div class="img" @click="play">
                <img src="../../../assets/images/student/horn.png" alt="">
              </div>
            </div>
          </div>
          <!-- 套红字母 -->
          <div class="overprint">
            <p><span v-for="(data, index) in toRed" :key="data + index"
                     :style="['a', 'e', 'i', 'o', 'u', 'A', 'E', 'I', 'O', 'U'].indexOf(data) !== -1 ? 'color: #FD3F3F' : ''">{{
                data
              }}</span></p>
          </div>
          <!-- 词义，可滚动 -->
          <div class="mean">
            <div class="menuoptions">
              <div class="every">
                <!-- 前四个  -->
                <div class="topfoue">
                  <p> {{ sound == 'yg' ? '[英]' : '[美]' }} {{ currentWord[sound + 'yb'] }} </p>
                  <p v-for="(data, index) in mean" :key="data + index">{{ data }}</p>
                </div>
                <div class="association">{{ '联想记忆法：' + (currentWord.sjf || '无') }}</div>
              </div>
            </div>
          </div>
          <div class="btns" @click="content = 'test1'">
            <img src="../../../assets/images/student/cooked.png" alt="">
            <span class="text">继 续</span>
          </div>
        </div>
        <!-- 第二轮测试 -->
        <div class="test2" v-if="content == 'test2'">
          <!-- 头 -->
          <div class="header">
            <div class="title">看义拼词</div>
            <p class="time"> 剩余 <span>{{ time }}</span> 秒 </p>
          </div>
          <!-- 词义 -->
          <div class="mean">
            <span @click.stop="showAll(currentWord.cy)"> {{ currentWord.cy }} </span>
          </div>
          <div class="options2">
            <div class="center">
              <div v-for="data in optionSelected" :key="data.txt" class="option2">{{ data.txt }}</div>
              <div class="del" @click="delAll" :style="optionSelected.length > 0 ? 'display:block;' : 'display:none;'">
                <i class="el-icon-circle-close"></i></div>
            </div>
          </div>
          <hr class="hr">
          <div class="answers2">
            <div class="answer2"
                 :class="{ selected: optionSelected.map((ele) => {return ele.index }).indexOf(index) !== -1 }"
                 v-for="(data, index) in optionAns" :key="data" @click="selected(data, index)">
              {{ data }}
            </div>
          </div>
          <div class="btns" @click="submit">
            <img src="../../../assets/images/student/cooked.png" alt="">
            <span class="text">提交</span>
          </div>
        </div>
      </div>
      <!-- 开始测试 -->
      <div class="test" v-if="content == 'test'">
        <div class="congratulations">
          <div class="image">
            <img src="../../../assets/images/student/congratulations.png" alt="">
            <em class="text">
              完成本次学习,快去测试看看吧!
            </em>
          </div>
        </div>
        <!-- 测试按钮 -->
        <div class="practice" @click="toTest">
          <img src="../../../assets/images/student/practice.png" alt="">
          <span class="text">开始测试</span>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogMsg" class="dialogMsg">
      <div class="dio">
        <img src="../../../assets/images/student/no1.png" v-if="dioAnswer == 'no1'" alt="">
        <img src="../../../assets/images/student/yes1.png" v-if="dioAnswer == 'yes1'" alt="">
        <div class="title" v-if="dioAnswer === 'no1'">正确答案</div>
        <div class="dioWord">{{ currentWord.nr }}</div>
        <!-- 加空白标签防止样式变性 -->
        <!-- <div class="dioWord" v-if="dioAnswer == 'yes1'"></div> -->
      </div>
    </el-dialog>
    <!-- <div class="showall" id="showall" @click.stop="showTip = true" v-show="showTip"></div> -->
  </div>
</template>
<script>
import {saveMyBreakpoint, updateCollectionWord, getMyBreakpoint} from '@/api/student'

export default {
  computed: {
    toRed() {
      return this.currentWord.dccf ? this.currentWord.dccf.split(',').join('-') : []
    },
    mean() {
      return this.currentWord.cy.split('；')
    },
    optionsChinese() {
      let arr = this.currentWord.yyh.split('##')
      arr.push(this.currentWord.cy)
      arr.sort(() => Math.random() - 0.5)
      return arr
    }
  },
  components: {
    studyHeader: (resolve) => {
      require(["@/components/student/studyHeader"], resolve)
    }
  },
  data() {
    return {
      content: '', // test完成 // default初始 // study学习 // test1第一轮测试 // test2第二轮测试
      wordList: [], // 单词列表
      currentWord: {}, // 当前单词
      currentIndex: 0, // 当前单词的索引
      submitData: {}, // 学习阶段产生的数据汇总
      clickStop: false, // 防高频点击
      sound: '', // 发音
      time: 20, // 倒计时
      timer: null, // 计时器
      ansYes: '', // 正确答案展示
      ansNo: '', // 错误答案展示
      optionSelected: [], // 看义拼词答案
      optionAns: [], // 看义拼词选项
      dialogMsg: false,
      dioAnswer: '', // 对的穿yes1  错的传no1
      source: '', // 来源 studyway学习之旅 wrong错词本
      backPath: '', // 返回上一页的地址
      showTip: false, // 控制showAll显示隐藏--文字超出时点击显示
      linkClick: true
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  created() {
    this.wordList = JSON.parse(sessionStorage.getItem('words'))
    this.source = this.$route.query.source
    this.sound = localStorage.getItem('sound_' + this.$store.state.userInfo.xm)
    if (this.source === 'studyway') {
      this.backPath = '/wordcontent?type=' + this.$route.query.type + '&bbid=' + this.$route.query.bbid + '&zid=' + this.$route.query.zid + '&dyid=' + this.$route.query.dyid + '&source=' + this.$route.query.source + '&bbmc=' + this.$route.query.bbmc
      getMyBreakpoint({dyid: ~~this.$route.query.dyid, xsid: this.$store.state.userInfo.yhid}).then(res => {
        if (res.success) {
          let notes = res.resultDesc === 'SUCCESS' ? '' : res.resultDesc
          if (notes) {
            this.submitData = JSON.parse(notes)
            this.submitData.startTime = new Date().getTime()
            if (this.submitData.list.length === this.wordList.length) {
              this.content = 'test'
            } else {
              this.currentIndex = this.submitData.list.length
              this.nextItem(this.currentIndex)
            }
          } else {
            let jxInfo = JSON.parse(sessionStorage.getItem('jxInfo'))
            this.submitData = {
              progress: 0,
              xsxm: this.$store.state.userInfo.xm,
              xb: Number(JSON.parse(sessionStorage.getItem('userInfo')).xb),
              bbid: Number(this.$route.query.bbid),
              bbmc: JSON.parse(sessionStorage.getItem('bbInfo')).find(ele => {
                if (ele.id === Number(this.$route.query.bbid)) {
                  return ele
                }
              }).bbmc,
              dyid: Number(this.$route.query.dyid),
              cjr: this.$store.state.userInfo.yhid,
              dymc: jxInfo.dymc,
              xsid: this.$store.state.userInfo.yhid,
              zid: Number(this.$route.query.zid),
              zmc: jxInfo.zmc,
              lxid: jxInfo.lxid,
              lxmc: jxInfo.lxmc,
              list: [],
              startTime: new Date().getTime(),
              xxsc: 0,
              xgr: this.$store.state.userInfo.yhid
            }
            this.nextItem(this.currentIndex)
          }
        } else {
          this.$message.error(res.resultDesc)
        }
      }).catch(err => {
        this.$message.error({message: '当前网络卡顿，请刷新后重试', duration: 0})
      })
    } else {
      this.backPath = '/wordcontent?source=' + this.source
      this.nextItem(this.currentIndex)
    }
  },
  methods: {
    // 题目超出时，点击展示全部
    showAll(val) {
      // var oDom = document.querySelector('.showall')
      // oDom.innerHTML = val
      this.showTip = !this.showTip
    },
    // 收藏
    collect(sfsc) {
      if (this.clickStop) {
        return ''
      }
      this.clickStop = true
      let jxInfo = JSON.parse(sessionStorage.getItem('jxInfo'))
      let data = {
        bbid: Number(this.$route.query.bbid),
        bbmc: this.$route.query.bbmc,
        cjr: this.$store.state.userInfo.yhid,
        dcid: this.currentWord.id,
        dcsy: this.currentWord.cy,
        dyid: Number(this.$route.query.dyid),
        dymc: jxInfo.dymc,
        lxid: jxInfo.lxid,
        lxmc: jxInfo.lxmc,
        mc: this.currentWord.nr,
        xgr: this.$store.state.userInfo.yhid,
        xsid: this.$store.state.userInfo.yhid,
        zid: Number(this.$route.query.zid),
        zmc: jxInfo.zmc,
        scbj: sfsc
      }
      updateCollectionWord(data).then(res => {
        if (res.success) {
          this.currentWord.sfsc = !sfsc
          this.wordList[this.currentIndex].sfsc = !sfsc
          sessionStorage.setItem('words', JSON.stringify(this.wordList))
          this.$message.success(sfsc ? '取消成功' : '收藏成功')
          this.clickStop = false
        } else {
          this.$message.warning(res.resultDesc)
        }
      })
    },
    back() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.$router.replace({path: this.backPath})
    },
    // 保存记录到本地
    preserve() {
      if (this.source === 'studyway') {
        this.submitData.xxsc = this.submitData.xxsc + parseInt((new Date().getTime() - this.submitData.startTime) / 1000)
        this.submitData.startTime = new Date().getTime()
        // cjlx 任务false集训true
        saveMyBreakpoint({
          cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
          cjlx: true,
          ddxx: JSON.stringify(this.submitData),
          dyid: Number(this.$route.query.dyid),
          xsid: this.$store.state.userInfo.yhid
        }).then(res => {
          console.log('学习记录保存数据库成功')
        }).catch(error => {
          this.$message.error({message: '当前网络卡顿，请刷新后重试', duration: 0})
        })
      }
    },
    // 打乱顺序
    shuffle(a, b) {
      return Math.random() > .5 ? -1 : 1
    },
    // 计时开始
    timeStart() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.time = 20
      this.timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearInterval(this.timer)
          this.timer = null
          this.optionSelected = []
          this.content = 'default'
        }
      }, 1000)
    },
    // 发音
    async play() {
      await this.$nextTick();
      // this.$refs.audio.play()
      const videoElement = this.$refs.audio
      if (videoElement && videoElement.paused) {
        try {
          await videoElement.play();
        } catch (error) {
          console.error('Failed to play media:', error);
        }
      }
    },
    // 进行下一词
    nextItem(index) {
      this.currentWord = this.wordList[index]
      this.content = 'default'
    },
    // 熟悉不熟悉
    isShuxi(sfsx) {
      if (this.clickStop) {
        return ''
      }
      this.clickStop = true
      if (this.source === 'studyway') {
        if (this.submitData.list.length && (this.submitData.list.map((ele) => {
          return ele.dcid
        }).indexOf(this.currentWord.id) !== -1)) {
          // 存过,替换
          this.submitData.list.find(ele => {
            if (ele.dcid === this.currentWord.id) {
              return ele
            }
          }).sfsx = sfsx
        } else {
          // 没存过,新增
          let data = {
            dcid: this.currentWord.id,
            dcmc: this.currentWord.nr,
            lx: this.currentWord.lx,
            sfsx: sfsx
          }
          this.submitData.list.push(data)
        }
      }
      this.content = sfsx ? 'test1' : 'study'
      this.$nextTick(() => {
        this.clickStop = false
      })
    },
    // test1提交
    doTest1(item) {
      if (this.clickStop) {
        return ''
      }
      this.clickStop = true
      this.ansYes = this.currentWord.cy
      clearInterval(this.timer)
      this.timer = null
      if (item === this.currentWord.cy) {
        // 做对了
          if (this.currentWord.lx) {
            if (this.currentIndex + 1 === this.wordList.length) {
              if (this.source === 'studyway') {
                this.submitData.progress = 1
                this.submitData.xxsc = this.submitData.xxsc + parseInt((new Date().getTime() - this.submitData.startTime) / 1000)
                this.submitData.startTime = new Date().getTime()
                // cjlx 任务false集训true
                saveMyBreakpoint({
                  cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
                  cjlx: true,
                  ddxx: JSON.stringify(this.submitData),
                  dyid: Number(this.$route.query.dyid),
                  xsid: this.$store.state.userInfo.yhid
                }).then(res => {
                  this.content = 'test'
                }).catch(error => {
                  this.$message.error({message: '当前网络卡顿，请刷新后重试', duration: 0})
                })
              } else {
                this.content = 'test'
                this.clickStop = false
              }
            } else {
              this.currentIndex++
              this.nextItem(this.currentIndex)
              this.preserve()
              this.clickStop = false
            }
          } else {
            let arr = (this.currentWord.dccf + ',' + this.currentWord.grcfx)
            this.optionAns = arr.split(',').splice(0, 8).sort(() => Math.random() - 0.5)
            this.content = 'test2'
            this.clickStop = false
          }
          this.ansNo = ''
          this.ansYes = ''
      } else {
        // 做错了
        this.ansNo = item
        setTimeout(() => {
          this.content = ''
          this.$nextTick(() => {
            this.ansNo = ''
            this.ansYes = ''
            this.content = 'study'
            this.clickStop = false
          })
        }, 1000)
      }
    },
    // test2点击选项
    selected(data, index) {
      if (this.optionSelected.map((ele) => {
        return ele.index
      }).indexOf(index) === -1) {
        this.optionSelected.push({txt: data, index: index})
      } else {
        this.optionSelected.splice(this.optionSelected.map((ele) => {
          return ele.index
        }).indexOf(index), 1)
      }
    },
    // test2删除选项
    delAll() {
      this.optionSelected = []
    },
    // test2提交答案
    submit() {
      if (this.clickStop) {
        return ''
      }
      this.clickStop = true
      if (this.optionSelected.length === 0) {
        this.$message.warning('请拼写成正确的单词')
        this.clickStop = false
      } else {
        clearInterval(this.timer)
        this.timer = null
        let word = ''
        this.optionSelected.forEach(ele => {
          word += ele.txt
        })
        if (word.toLowerCase() === this.currentWord.nr.toLowerCase()) {
          this.dioAnswer = 'yes1'
          this.$nextTick(() => {
            // this.dialogMsg = true
              if (this.currentIndex + 1 === this.wordList.length) {
                if (this.source === 'studyway') {
                  this.submitData.progress = 1
                  this.submitData.xxsc = this.submitData.xxsc + parseInt((new Date().getTime() - this.submitData.startTime) / 1000)
                  this.submitData.startTime = new Date().getTime()
                  // cjlx 任务false集训true
                  saveMyBreakpoint({
                    cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
                    cjlx: true,
                    ddxx: JSON.stringify(this.submitData),
                    dyid: Number(this.$route.query.dyid),
                    xsid: this.$store.state.userInfo.yhid
                  }).then(res => {
                    this.content = 'test'
                  }).catch(error => {
                    this.$message.error({message: '当前网络卡顿，请刷新后重试', duration: 0})
                  })
                } else {
                  this.content = 'test'
                }
              } else {
                this.currentIndex++
                this.nextItem(this.currentIndex)
                this.preserve()
              }
              this.optionSelected = []
              this.dioAnswer = ''
              // this.dialogMsg = false
              this.clickStop = false
          })
        } else {
          this.dioAnswer = 'no1'
          this.$nextTick(() => {
            this.dialogMsg = true
            this.optionAns.sort(this.shuffle)
            setTimeout(() => {
              this.optionSelected = []
              this.timeStart()
              this.dialogMsg = false
              this.clickStop = false
            }, 1000)
          })
        }
      }
    },

    // 去单元测试
    toTest() {
      if (this.source === 'studyway') {
        if (!this.linkClick) {
          return false
        }
        this.linkClick = false
        getMyBreakpoint({dyid: ~~this.$route.query.dyid, xsid: this.$store.state.userInfo.yhid}).then(res => {
          if (res.success) {
            let notes = res.resultDesc === 'SUCCESS' ? '' : res.resultDesc
            if (notes) {
              let submitData = JSON.parse(notes)
              if (submitData.progress == 1) {
                this.$router.replace({
                  name: 'testpage',
                  query: {
                    type: this.$route.query.type,
                    bbid: this.$route.query.bbid,
                    zid: this.$route.query.zid,
                    dyid: this.$route.query.dyid,
                    source: this.$route.query.source,
                    bbmc: this.$route.query.bbmc
                  }
                })
              } else {
                this.$message.error('正在保存学习记录，请稍后重试')
                this.linkClick = true
              }
            }
          } else {
            this.$message.error(res.resultDesc)
            this.linkClick = true
          }
        }).catch(err => {
          this.linkClick = true
          this.$message.error('当前网络卡顿，请刷新后重试')
        })
      } else {
        this.$router.replace({name: 'testpage', query: {source: this.source}})
      }
    }
  },
  watch: {
    async content(val) {
      this.showTip = false;
      try {
        switch (val) {
          case 'default':
            await this.play();
            break;
          case 'study':
            await this.play();
            break;
          case 'test1':
            this.timeStart();
            await this.play();
            break;
          case 'test2':
            this.optionSelected = [];
            this.timeStart();
            break;
        }
      } catch (error) {
        console.error('An error occurred during media playback:', error);
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-height: 700px) {
  #studyIpad {
    .unitsTitle {
      display: none;
    }

    .main {
      position: absolute;
      width: 80%;
      height: 80%;
      // border: 1px solid red;
      padding: 1vw 5vw 2vw;
      top: 50%;
    }

    .progress {
      height: 16%;
    }

    .word {
      height: 20%;
      font-size: 2.5vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .study {
      .word {
        height: 100%;
        font-size: 3.5vw;
        overflow: inherit;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .audio {
      height: 28%;

      img {
        width: 5vw;
        height: 5vw;
      }
    }

    .mean {
      font-size: 3.5vw;
    }

    .btns {
      bottom: 2vw;
      height: 13%;

      .btntext {
        font-size: 2vw;
      }
    }

    .test1 {
      .header {
        height: 10%;

        .title {
          font-size: 2vw;
        }
      }

      .wordaudio {
        .word {
          height: 100%;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .img {
            top: 4px;
          }
        }
      }

      .options {
        .option {
          height: 25%;
        }

        .answer {
          width: 80%;
          padding: 2% 0;
          font-size: 2.5vw;
        }
      }
    }

    .test2 {
      .header {
        height: 13%;

        .title {
          font-size: 2vw;
        }
      }

      .options2 {
        .option2 {
          padding: 1.5vw;
          font-size: 3vw;
        }
      }

      .mean {
        height: 12%;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 100%;
          line-height: 3.5vw;
        }
      }

      .answers2 {
        width: 70%;

        .answer2 {
          height: 6vw;
          line-height: 6vw;
          font-size: 2.4vw;
        }
      }

      .btns {
        bottom: 0;
      }

      .options2 {
        .del {
          font-size: 3vw;
          right: -5vw;
        }
      }
    }

    .study {
      .progress {
        height: 10%;
      }

      .overprint {
        font-size: 3vw;
        height: 10%;
      }

      .mean {
        .topfoue {
          font-size: 2.5vw;
          line-height: 2.5vw;
        }

        .association {
          font-size: 2.5vw;
          line-height: 4vw;
        }
      }

      .btns {
        bottom: 0vw;
      }

      .wordaudio {
        .img {
          top: 8px;
        }
      }
    }
  }
  #showall {
    font-size: 3vw;
  }
}

.showall {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  background: #5990ff;
  color: #fff;
  font-size: 2vw;
  padding: 5px 10px;
  border-radius: 20px;
}

.dialogMsg {
  .dio {
    background: #fff;
    border-radius: 22px;
    padding-top: 20px;

    img {
      display: block;
      margin: 30px auto;
      margin-bottom: 50px;
    }

    .title {
      text-align: center;
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    .dioWord {
      text-align: center;
      font-size: 28px;
      line-height: 28px;
      padding-bottom: 30px;
    }
  }
}

.studyBox {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url("../../../assets/images/student/learnwords.png") no-repeat;
  background-size: 100% 100%;

  .main {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 75%;
    background: url("../../../assets/images/student/unitBg.png") no-repeat;
    background-size: 100% 100%;
    padding: 5vw;
    padding-bottom: 4vw;

    .unitsTitle {
      width: 50%;
      display: inline-block;
      position: absolute;
      top: -10%;
      left: 50%;
      transform: translateX(-50%);

      img {
        display: block;
        width: 100%;
        height: 100%;
      }

      .text {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
        color: #000;
        font-size: 1.7vw;
        padding-top: 0.5vw;
      }
    }
  }

  .congratulations {
    width: 34vw;
    height: 25vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    .image {
      img {
        display: block;
        width: 100%;
        height: 100%;
      }

      .text {
        position: absolute;
        bottom: 15%;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 1.2rem;
      }
    }
  }

  .practice {
    width: 13rem;
    height: 4rem;
    position: fixed;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .text {
      position: absolute;
      top: 0;
      text-align: center;
      color: #fff;
      height: 100%;
      width: 100%;
      font-size: 28px;
      line-height: 4rem;
    }
  }

  // 松鼠
  .squirrel {
    position: absolute;
    top: 23%;
    left: 8%;
    width: 8vw;
    height: 6.57vw;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  // 初始
  .default {
    width: 100%;
    height: 100%;

    .progress {
      height: 20%;
      width: 70%;
      margin: auto;
      position: relative;

      /deep/ .el-progress {
        width: 100%;
      }

      .text {
        text-align: right;
        font-size: 1.5vw;
        position: absolute;
        right: -6vw;
        top: -5px;
        color: #5990ff;
      }
    }

    .word {
      text-align: center;
      height: 23%;
      color: #333;
      font-size: 3vw;
      line-height: 3vw;
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: auto;
    }

    .audio {
      width: 100%;
      text-align: center;
      height: 32%;

      img {
        display: block;
        width: 4vw;
        height: 4vw;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .mean {
      width: 100%;
      height: 23%;
      text-align: center;
      color: #333;
      font-size: 2.5vw;
      line-height: 2.5vw;
      font-family: Arial, Helvetica, sans-serif;
    }

    .btns {
      position: absolute;
      bottom: -0.6vw;
      width: 38%;
      left: 50%;
      height: 10%;
      transform: translateX(-50%);

      .btnleft {
        width: 45%;
        height: 100%;
        position: relative;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }

        .btntext {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.6vw;
          color: #fff;
        }
      }

      .btnright {
        width: 48%;
        height: 100%;
        position: relative;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }

        .btntext {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.6vw;
          color: #333;
        }
      }
    }
  }

  //   第一次集训
  .test1 {
    width: 100%;
    height: 100%;

    .header {
      width: 100%;
      height: 20%;
      position: relative;

      .title {
        width: 50%;
        text-align: center;
        margin: auto;
        font-size: 1.9vw;
        color: #333;
      }

      .time {
        position: absolute;
        right: 2vw;
        top: 0.3vw;
        font-size: 1.7vw;

        span {
          color: #ff4545;
          font-size: 1.8vw;
        }
      }
    }

    .wordaudio {
      width: 100%;
      height: 20%;
      text-align: center;

      .word {
        color: #333;
        // 文字撑开div
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        font-size: 2vw;
        position: relative;
        margin: auto;
        max-width: 90%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        .img {
          position: absolute;
          right: 0;
          width: 4vw;
          height: 4vw;
          top: -10px;
          right: -6vw;
          cursor: pointer;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .options {
      width: 100%;
      height: 65%;
      // border: 1px solid red;
      .option {
        position: relative;
        height: 20%;
        margin-bottom: 1%;

        .answer {
          box-sizing: border-box;
          cursor: pointer;
          padding: 1.5% 0;
          width: 50%;
          text-align: center;
          font-size: 1.5vw;
          margin: auto;
          border: 2px solid #ffffff;
          border-radius: 10px;
          color: #003333;
          line-height: 1.5vw;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .icon {
            width: 3vw;
            height: 3vw;
            position: absolute;
            top: 0;
            right: -5vw;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .test2 {
    width: 100%;
    height: 100%;

    .header {
      width: 100%;
      height: 20%;
      position: relative;

      .title {
        width: 50%;
        text-align: center;
        margin: auto;
        font-size: 1.9vw;
        color: #333;
      }

      .time {
        position: absolute;
        right: 2vw;
        top: 0.3vw;
        font-size: 1.7vw;

        span {
          color: #ff4545;
          font-size: 1.8vw;
        }
      }
    }

    .mean {
      height: 14%;
      text-align: center;
      font-size: 1.9vw;
      line-height: 1.9vw;
      color: #333;

      span {
        max-width: 90%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin: auto;
      }
    }

    .options2 {
      margin: auto;
      height: 16%;

      .center {
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        position: relative;
        margin: auto;

        .del {
          font-size: 2vw;
          position: absolute;
          right: -3vw;
          display: none;

          i {
            color: #666;
            cursor: pointer;
          }
        }
      }

      .option2 {
        font-size: 2vw;
        float: left;
        padding: 0.5vw;
        background: #5990ff;
        color: #fff;
        border-radius: 6px;
        line-height: 2vw;
        margin-right: 5px;
      }
    }

    .hr {
      width: 40%;
      height: 2px;
      background: #000;
      margin: 0 auto;
    }

    .answers2 {
      padding-top: 15px;
      width: 50%;
      height: 49%;
      margin: 0 auto;
      overflow-y: auto;

      .answer2 {
        width: 22%;
        height: 4vw;
        line-height: 4vw;
        margin-left: 3%;
        font-size: 1.5vw;
        float: left;
        text-align: center;
        border: 2px solid #fff;
        border-radius: 6px;
        cursor: pointer;
        box-sizing: border-box;
        margin-bottom: 20px;
      }

      .answer2.selected {
        background: #5990ff;
        border: 1px solid #5990ff;
        color: #fff;
      }
    }

    .btns {
      position: absolute;
      bottom: -0.6vw;
      width: 15%;
      left: 50%;
      height: 10%;
      transform: translateX(-50%);

      img {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      span {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.6vw;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .study {
    width: 100%;
    height: 100%;

    .progress {
      height: 10%;
      width: 70%;
      margin: auto;
      position: relative;

      /deep/ .el-progress {
        width: 100%;
      }

      .text {
        text-align: right;
        font-size: 1.5vw;
        position: absolute;
        right: -6vw;
        top: -5px;
        color: #5990ff;
      }
    }

    .wordaudio {
      width: 100%;
      height: 17%;
      text-align: center;

      .word {
        color: #333;
        // 文字撑开div
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        font-size: 2.5vw;
        position: relative;
        margin: auto;

        .img {
          position: absolute;
          right: 0;
          width: 4vw;
          height: 4vw;
          top: -7px;
          right: -6vw;
          cursor: pointer;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .overprint {
      width: 100%;
      height: 13%;
      text-align: center;
      color: #333;
      font-size: 2vw;
      line-height: 2vw;
    }

    .mean {
      width: 80%;
      height: 60%;
      border: 2px solid #000000;
      border-radius: 20px;
      margin: auto;
      position: relative;

      .menuoptions {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow-y: scroll;
        width: 100%;
        height: 90%;

        .every {
          color: #333;
          font-size: 1.6vw;
          line-height: 1.6vw;
          margin-bottom: 20px;

          .topfoue {
            width: fit-content;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            margin: auto;

            p {
              margin-bottom: 1vw;
            }
          }

          .association {
            line-height: 2vw;
            padding-left: 5%;
          }
        }
      }
    }

    .btns {
      position: absolute;
      bottom: -0.6vw;
      width: 15%;
      left: 50%;
      height: 10%;
      transform: translateX(-50%);

      img {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      span {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.6vw;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.studymain {
  .studyBox {
    .progress {
      .el-progress {
        width: 70%;
        margin: auto;
      }

      .el-progress-bar__inner {
        background: linear-gradient(251deg, #3862d9, #4eb7fb);
      }

      .el-progress-bar__outer {
        height: 20px !important;

        .el-progress-bar__innerText {
          display: none;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #87c2ef;
      border-radius: 20px;
    }
  }

  .dialogMsg {
    .el-dialog {
      width: 400px;
      border-radius: 22px;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 18px 14px 18px;
      // background: #daffa5;
      background: #4cb2f1;
      border-radius: 22px;
    }
  }
}
</style>