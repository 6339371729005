import axios from './index'
// 判断该次集训是否需要前测
export const isHaveEntranceTest = params => {
    return axios.post(`api/8790/dcTestResult/getIsHaveBeforeTest`, params)
}
// 查询我的断点
export const getMyBreakpoint = params => {
    return axios.post(`api/8790/dcMyBreakpoint/getMyBreakpoint`, params)
}
// 获取单词类型
export const wordType = params => {
    return axios.post(`api/8780/words/getWordsType`, params)
}
// 根据单词类型获取版本
export const isHaveBb = params => {
    return axios.post(`api/8780/words/getWordsEdition`, params)
}
// 根据版本获取章
export const isHaveZ = params => {
    return axios.post(`api/8780/words/getWordsChapter`, params)
}
// 屏幕状态
export const getPmzt = params => {
    return axios.post(`api/8710/classOnOff/getPmzt`, params)
}
// 根据班级获取集训
export const getTrainingUsTime = params => {
    return axios.post(`api/8400/training/getTrainingUsTime`, params)
}
// 根据章获取单元
export const isHaveUnit = params => {
    return axios.post(`api/8780/words/getWordsUnit`, params)
}

// 根据单元下的单词
export const getWords = params => {
    return axios.post(`api/8780/words/getWords`, params)
}
// 提交单词
export const submitWords = params => {
    return axios.post(`api/8780/words/saveWordsResult`, params)
}
// 集训下拉框
export const getTrains = params => {
    return axios.get(`api/8100/trainings/selectTraining`, params)
}
// 根据集训查询班级和小组
export const getGroupStudentCount = params => {
    return axios.post(`api/8720/student/getGroupStudentCount`, params)
}
// 错题本首页数据
export const getErrorWordHome = params => {
    return axios.post(`api/8790/dcErrorWord/getDcErrorWordHome`, params)
}
// 错词列表
export const getErrorWordList = params => {
    return axios.post(`api/8790/dcErrorWord/getDcErrorWordList`, params)
}
// 巩固错词
export const getWrongWords = params => {
    return axios.post(`api/8720/words/errorWordConsolidate`, params)
}
// 学情分析
export const getStudyAnalysis = params => {
    return axios.post(`api/8780/studyAnalysis/getStudyAnalysis`, params)
}
// 学生金币总数
export const goldSum = params => {
    return axios.get(`api/8790/dcStudentCoin/getStudentCoinTotal`, params)
}
// 金币排行榜
export const getCoinRanking = params => {
    return axios.post(`api/8720/studentCoinDetail/getCoinRanking`, params)
}
// 查询该学生测试成绩
export const getTestList = params => {
    return axios.post(`api/8790/dcTestResult/getDcTestList`, params)
}
// 查询该学生金币明细
export const getCoinsList = params => {
    return axios.post(`api/8780/studentCoinDetail/getDcStudentCoinDetailList`, params)
}
// 保存该学生学习记录
export const saveMyBreakpoint = params => {
    return axios.post(`api/8780/words/saveMyBreakpoint`, params)
}
// 意见反馈提交
export const addFeedback = params => {
    return axios.post(`api/8720/feedback/addFeedback`, params)
}
// 分页查询物品
export const selectExchangeItemsPage = (params) => {
    return axios.post(`api/8700/exchangeItems/selectExchangeItemsPage`, params)
}
// 发起兑换
export const saveItemExchange = (params) => {
    return axios.post(`api/8700/itemExchange/saveItemExchange`, params)
}
// 获取班级（常规班）id
export const selectClassByYhid = (params) => {
    return axios.get(`api/8100/class/selectClassByYhid`, params)
}
// 收藏或取消单词
export const updateCollectionWord = (params) => {
    return axios.post(`api/8780/dcCollectionWord/updateDcCollectionWord`, params)
}
// 我的收藏
export const myCollectionWord = (params) => {
    return axios.post(`api/8780/dcCollectionWord/myDcCollectionWord`, params)
}
// 移除收藏
export const deleteCollectionWord = (params) => {
    return axios.post(`api/8780/dcCollectionWord/deleteDcCollectionWord`, params)
}
// 新增个性化设置
export const addPersonalConfig = (params) => {
    return axios.post(`api/8720/personalConfig/addPersonalConfig`, params)
}
// 我的个性化设置
export const myPersonalConfig = (params) => {
    return axios.post(`api/8720/personalConfig/myPersonalConfig`, params)
}
// 修改个性化设置
export const updatePersonalConfig = (params) => {
    return axios.post(`api/8720/personalConfig/updatePersonalConfig`, params)
}
// 获取学习小贴士
export const getStudytips = (params) => {
    return axios.post(`api/8780/studyAnalysis/getStudytips`, params)
}

// ================前后测试=======================
// 结业测试 / 入学测试 获取下一题
export const nextItem = params => {
    return axios.get(`/api/8760/wordItem/suggest`, params)
}
// 结业测试 / 入学测试 提交答案
export const submitTotalTest = params => {
    return axios.post(`/api/8760/answerItem/submit`, params)
}
// 结业测试 / 入学测试 交卷
export const submitTest = params => {
    return axios.post(`/api/8760/pkAdmissionTest/submitTestV3`, params)
}
// 结业测试 / 入学测试 成绩查询
export const getMyTestResult = params => {
    return axios.post(`/api/8760/pkAdmissionTest/getMyTest`, params)
}
// ================end=======================